import React, { useState, useMemo, useRef } from "react";
import {
	BasePopover,
	cn,
	DataObserveKey,
	FocusableComponent,
	Paragraph,
	useUniqueId,
	VisualComponent,
} from "@siteimprove/fancylib";
import {
	InputField,
	InputFieldProps,
	InputFieldWithSlug,
	InputFieldWithSlugProps,
} from "../input-field/input-field";
import { OptionItem, useFilteredOptions, useSelectStateManager } from "../select/select";
import { SelectListbox, SelectListboxProps } from "../select/select-listbox";
import { Trigger, setFocus } from "../../../utils/dom-utils";
import { combinedRefs, stopEvent } from "../../../utils/shorthands";
import { useDidUpdate, useOnClickOutside } from "../../../utils/hooks";
import { KeyCode, isBlurNavigatingOut } from "../../../utils/keyboard-nav-utils";
import { FormControl } from "../form/form";
import { PopoverProps } from "../../overlay/popover/popover";
import * as popoverScss from "../../overlay/popover/popover.scss";
import * as selectScss from "../select/select.scss";
import { useLabTranslations } from "../../../translations/translations";

type AutoCompleteProps = FormControl<string> & {
	/** List of options to display in the listbox */
	suggestions: string[];
	/** Handler for searching event */
	onSearch?: (query: string, caseSensitive: boolean) => void;
} & Pick<SelectListboxProps<string>, "caseSensitive" | "loading"> &
	Pick<PopoverProps, "placement" | "allowedAutoPlacements"> &
	Pick<
		InputFieldProps,
		"autoFocus" | "disabled" | "fullWidth" | "large" | "placeholder" | "width"
	> &
	Pick<InputFieldWithSlugProps, "leftSlug" | "rightSlug"> &
	DataObserveKey &
	VisualComponent &
	FocusableComponent;

export function AutoComplete(props: AutoCompleteProps): JSX.Element {
	const {
		allowedAutoPlacements,
		caseSensitive,
		suggestions,
		id: propId,
		loading,
		onChange,
		onSearch,
		placement,
		value,
		// extract some aria props here to remove it from ...rest
		"aria-label": ariaLabel,
		"aria-labelledby": ariaLabelledBy,
		...rest
	} = props;

	const optionItems: OptionItem<string>[] = useMemo(() => itemize(suggestions), [suggestions]);
	const [filteredItems] = useFilteredOptions(
		optionItems,
		value,
		caseSensitive,
		undefined,
		onSearch
	);
	const [isOpen, setIsOpen] = useState(false);
	const [isQueryChanged, setIsQueryChanged] = useState(true);
	const [popoverRef, inputRef, , popoverContentEvents] = usePopoverContent(isOpen, setIsOpen);
	const selectionManager = useSelectStateManager<string>([], false, undefined);

	const [activeDescendantId, setActiveDescendantId] = useState<string | undefined>();
	const listboxId = useUniqueId("listbox");
	const inputId = useUniqueId("autocomplete");
	const id = propId || inputId;
	const firstOptionRef = useRef<HTMLDivElement>(null);
	const handleOnKeyDown = useKeyboardSupport(isOpen, setIsOpen, onChange, firstOptionRef);
	const i18nLab = useLabTranslations();

	const handleAcceptSuggestion = (option: OptionItem<string>) => {
		const newSelection = selectionManager.toggleOption(option);
		onChange(newSelection[0]?.value);
		setIsQueryChanged(false);
		setIsOpen(false);
		inputRef.current?.focus();
	};

	const handleOnChange = (newValue: string) => {
		setIsOpen(newValue !== "" && isQueryChanged);
		setIsQueryChanged(true);
		onChange(newValue);
	};

	return (
		<BasePopover
			allowedAutoPlacements={allowedAutoPlacements}
			placement={placement || "bottom-start"}
			showPopover={isOpen}
			popover={
				<section
					className={cn(popoverScss.dropdownBox, popoverScss.noMaxWidth)}
					ref={popoverRef}
					{...popoverContentEvents}
				>
					{filteredItems.length > 0 && (
						<SelectListbox<string>
							aria-label={ariaLabel}
							aria-labelledby={cn(ariaLabelledBy)}
							caseSensitive={caseSensitive}
							className="fancy-SelectListbox"
							firstOptionRef={firstOptionRef}
							id={listboxId}
							items={filteredItems}
							loading={loading}
							needle={value}
							onToggleOption={handleAcceptSuggestion}
							onSelectBulk={() => {}}
							onDeselectBulk={() => {}}
							onFocusOption={(e) => setActiveDescendantId(e.target.id)}
							value={[]}
						/>
					)}
					{filteredItems.length === 0 && (
						<Paragraph
							tone="subtle"
							lineHeight="single-line"
							className={selectScss.noMatchesMessage}
						>
							{i18nLab.noMatches}
						</Paragraph>
					)}
				</section>
			}
			anchor={(ref) => {
				const commonProps = {
					...rest,
					"aria-activedescendant": activeDescendantId,
					"aria-autocomplete": "list",
					"aria-controls": listboxId,
					"aria-expanded": isOpen,
					"aria-labelledby": id,
					autoComplete: "off",
					"data-component": "auto-complete",
					"data-observe-key": props["data-observe-key"],
					id: id,
					onChange: handleOnChange,
					onKeyDown: handleOnKeyDown,
					ref: combinedRefs(inputRef, ref),
					role: "combobox",
					value: value,
				};

				return props.leftSlug || props.rightSlug ? (
					<InputFieldWithSlug
						{...commonProps}
						leftSlug={props.leftSlug}
						rightSlug={props.rightSlug}
					/>
				) : (
					<InputField {...commonProps} />
				);
			}}
		></BasePopover>
	);
}

function itemize(suggestions: string[]): OptionItem<string>[] {
	return suggestions.map((item) => ({
		title: item,
		value: item,
	}));
}

function useKeyboardSupport(
	isOpen: boolean,
	setIsOpen: (isOpen: boolean) => void,
	onChange: (newValue: string) => void,
	firstOptionRef: React.RefObject<HTMLDivElement>
): (e: React.KeyboardEvent<HTMLInputElement>) => void {
	return (event: React.KeyboardEvent<HTMLInputElement>) => {
		// Should do nothing if the default action has been cancelled
		if (event.defaultPrevented) {
			return;
		}

		// key: CTRL + OPTION/ALT + SPACE set
		// - opens the listbox
		if (event.ctrlKey && event.altKey && event.keyCode === KeyCode.Space) {
			setIsOpen(true);
			event.preventDefault();
			return;
		}

		// key: Escape
		// - If the listbox is displayed, closes it.
		// - If the listbox is not displayed, clears the textbox.
		if (event.keyCode === KeyCode.Escape) {
			isOpen ? setIsOpen(false) : onChange("");
			event.preventDefault();
			event.stopPropagation();
			return;
		}

		// key: ArrowDown
		// - If the listbox is displayed, moves focus to the next option.
		// - If the listbox is not displayed, opens the listbox.
		if (event.keyCode === KeyCode.ArrowDown) {
			!isOpen && setIsOpen(true);
			firstOptionRef.current?.focus();
			event.preventDefault();
			return;
		}
	};
}

function usePopoverContent(
	isOpen: boolean,
	setIsOpen: (isOpen: boolean) => void
): [
	popoverRef: React.RefObject<HTMLElement>,
	anchorRef: React.RefObject<HTMLElement>,
	firstFocusableRef: React.RefObject<HTMLElement>,
	events: {
		onBlur: (event: React.FocusEvent<HTMLElement>) => void;
		onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void;
		onMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
	}
] {
	const anchorRef = React.createRef<HTMLElement>();
	const popoverRef = React.createRef<HTMLElement>();
	const firstFocusableRef = React.createRef<HTMLElement>();

	//Keeps track of what is currently triggering events
	const currentTrigger = useRef(Trigger.Mouse);
	const whatTriggered = useRef(Trigger.Mouse);

	const handleToggle = (open: boolean, trigger: Trigger) => {
		whatTriggered.current = trigger;
		setIsOpen(open);
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		currentTrigger.current = Trigger.Keyboard;
		if (e.keyCode === KeyCode.Escape) {
			stopEvent(e);
			handleToggle(false, Trigger.Keyboard);
		}
	};

	const onMouseDown = () => (currentTrigger.current = Trigger.Mouse);

	const onBlur = (e: React.FocusEvent<HTMLElement>) => {
		if (currentTrigger.current === Trigger.Keyboard && isBlurNavigatingOut(e)) {
			handleToggle(false, Trigger.Keyboard);
		}
	};

	useOnClickOutside(popoverRef, () => {
		handleToggle(false, Trigger.Mouse);
	});

	useDidUpdate(() => {
		if (isOpen && firstFocusableRef.current) {
			setFocus(firstFocusableRef.current, whatTriggered.current);
		} else if (!isOpen && whatTriggered.current == Trigger.Keyboard && anchorRef.current) {
			setFocus(anchorRef.current, whatTriggered.current);
		}
	}, [isOpen]);

	return [popoverRef, anchorRef, firstFocusableRef, { onBlur, onKeyDown, onMouseDown }];
}
